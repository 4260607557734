// scss-docs-start color-variables
$red: #f6424b !default;
$blue: #444fe6 !default;
$green: #3eb666 !default;
$yellow: #efa400 !default;
$dark: #292A2A !default;
$gray-600: #757f88 !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $dark !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:        $blue !default;
$danger:        $red !default;
$warning:        $yellow !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":    $info,
  "danger":     $danger,
  "warning":     $warning,
  "dark":       $dark
) !default;
// scss-docs-end theme-colors-map

$min-contrast-ratio: 2 !default;

$enable-rounded: true !default;

$font-family-base: 'Pretendard', 'NanumGothic', system-ui, -apple-system, arial !default;

$line-height-base: 1.44 !default;

$font-size-base: .875rem !default;

$body-bg: #f5f7fb;

$input-bg: #fff !default;
$input-placeholder-color: #a0a7af !default;

$nav-tabs-border-color: $dark !default;
$nav-tabs-border-width: 2px !default;
$nav-tabs-link-active-border-color: $dark $dark $body-bg $dark !default;
$nav-tabs-link-hover-border-color: #c6cbcf;

$card-spacer-x: 1.5rem !default;
$card-spacer-y: 1.5rem !default;
$card-cap-padding-y: 1rem !default;

$modal-lg: 700px !default;
$modal-xl: 1140px !default;